<template>
  <div class="CitasForm">
    <section class="technical-drawing__section empresas-login">
      <header class="empresas-login__header">
        <div class="empresas-login__inner container">
          <div class="empresas-login__header-content">
            <h2 class="empresas_home__heading heading">¡Se ha programado tu cita!</h2>
            <v-row>
              <v-col
                cols="12"
                sm="8"
                md="9"
              >
                <v-row class="mb24">
                  <v-col
                    cols="12"
                    sm="6"
                    md="3"
                  >
                    <div
                      class="clave_reservacion mt24"
                      style="min-width:100%"
                    >
                      <div class="clave_reservacion-value">
                        <strong class="upper">{{cita.folio}}</strong>
                      </div>
                      <div class="clave_reservacion-text">Clave de reservación</div>
                    </div>
                  </v-col>
                </v-row>
                <div
                  class="mt24"
                  v-if="cita.tipo ==='clinica'"
                >
                  <p>Te esperamos el día <strong>{{_formatFecha()}}</strong> en la clínica <strong style="text-transform:capitalize">{{cita.clinica}}</strong> ubicada en <strong style="text-transform:capitalize">{{cita.domicilio}}</strong>
                  </p>
                  <p>Te agradeceríamos <strong>presentarte con 15 minutos de antelación</strong> para realizarte la toma de signos vitales y así ingreses a tu cita a la hora acordada.
                  </p>
                  <p>
                    Así mismo, por respeto al tiempo de los demás pacientes solo <strong>tendremos 5 minutos de tolerancia</strong> después de la hora en que programaste tu cita, de otra forma esta será cancelada.</p>
                </div>
                <div
                  class="mt24"
                  v-else
                >
                  <p>El día <strong>{{_formatFecha()}}</strong> uno de nuestros médicos se estará presentando en el domicilio que nos indicaste.</p>
                </div>
              </v-col>

              <v-col
                cols="12"
                sm="4"
                md="3"
                class="d-none d-sm-flex"
              >
                <img
                  class="is-img-dr"
                  :src="_imgDr()"
                  style="height:auto;max-height:400px; max-width:100%!important;"
                />
              </v-col>
            </v-row>
          </div>
        </div>
      </header>
    </section>
  </div>
</template>
<script>
import forms from "@/mixins/forms.js";
export default {
  name: "CitasForm",
  mixins: [forms],
  data() {
    return {
      cita: {
        id: null,
        folio: null,
        fecha: null,
        domicilio: null,
        domicilio_cita: null,
        tipo: null,
        paciente: null,
        celular: null,
        clinica: null,
      },
    };
  },
  methods: {
    _formatFecha() {
      if (this.cita.fecha) {
        return this.$Datetime
          .fromSQL(this.cita.fecha)
          .setLocale("es-Mx")
          .setZone("America/Mexico_City")
          .toFormat("d 'de' LLLL 'a las' h:mm a");
      } else {
        return "";
      }
    },
    _imgDr() {
      return require("@/assets/avatars/dr0" +
        (Math.floor(Math.random() * 2) + 1) +
        ".png");
    },
  },
  created() {
    //asdiuhjasku
    this._getThings("citas/" + this.$route.params.idCit, "cita");
    this.$emit("visible", false);
    this.$emit("classes", "");
  },
  beforeDestroy() {
    this.$headers.headers.Token = null;
  },
};
</script>
<style lang="scss">
@media (max-width: 770px) {
  .is-img-dr {
    margin-top: 200px;
  }
}
@media (min-width: 771px) and (max-width: 880px) {
  .is-img-dr {
    margin-top: 100px;
  }
}
@media (min-width: 881px) and (max-width: 1200px) {
  .is-img-dr {
    margin-top: 80px;
  }
}
</style>
